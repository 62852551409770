var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{attrs:{"rounded":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"caption ma-0 ml-4"},[_vm._v(_vm._s(_vm.$_strings.invoice.INVOICE_NO)+" : "+_vm._s(_vm.noInvoice ? _vm.noInvoice : '-'))]),_c('p',{staticClass:"caption ma-0 ml-4"},[_vm._v(_vm._s(_vm.$_strings.invoice.ORDER_PERIODE)+" : "+_vm._s(_vm.invoicePeriod.invoiceGroup)+" "+_vm._s(_vm.invoicePeriod.startDate)+" s/d "+_vm._s(_vm.invoicePeriod.endDate))])]),_c('v-col',{staticClass:"ml-md-auto ma-4 mr-6",attrs:{"cols":"auto"}},[(!_vm.noInvoice)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.updateFixedInvoice()}}},[_vm._v(_vm._s(_vm.$_strings.invoice.TITLE_EDIT))]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.itemsInvoice,"expanded":_vm.expanded,"item-key":"limitId","show-expand":"","server-items-length":_vm.invoiceListTotalEntry,"options":_vm.pagination,"loading":_vm.isLoading,"loading-text":_vm.$_strings.order.LOADING_TEXT,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"item-expanded":function (ref) {
	var value = ref.value;
	var item = ref.item;

	return value && _vm.fetchSubInvoice(item);
}},scopedSlots:_vm._u([{key:"item.invoiceNo",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.invoiceNo ? item.invoiceNo : '-'))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.invoiceNo)?_c('p',{staticClass:"ma-0"},[_vm._v("Rp. "+_vm._s(_vm.formatAmount(item.price)))]):_c('v-text-field',{staticClass:"caption input-price",attrs:{"min":"0","step":"1","outlined":"","dense":"","prefix":"Rp.","id":"price","value":_vm.formatAmount(item.price)},on:{"change":function (e) { return _vm.onChangePrice(e, item); }},nativeOn:{"input":function($event){return _vm.changePrice($event, index)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var subItem = ref.item;
return [(subItem.subLoading)?[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1)],1)]:(subItem.subInvoice)?[_c('td',{staticClass:"white",attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-2 rounded",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.headerSubs,"calculate-widths":"","hide-default-footer":"","items":subItem.subInvoice}})],1)],1)],1)],1)],1)]:_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.invoice.INVOICE_LIST)+" "),(_vm.itemsInvoice.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }