<template>
  <v-container fluid>
    <v-sheet rounded>
      <v-row>
        <v-col>
          <p class="caption ma-0 ml-4">{{$_strings.invoice.INVOICE_NO}} : {{noInvoice ? noInvoice : '-'}}</p>
          <p class="caption ma-0 ml-4">{{$_strings.invoice.ORDER_PERIODE}} : {{invoicePeriod.invoiceGroup}} {{invoicePeriod.startDate }} s/d {{invoicePeriod.endDate}}</p>
        </v-col>
        <v-col
          cols="auto"
          class="ml-md-auto ma-4 mr-6"
        >
          <v-btn
            v-if="!noInvoice"
            color="primary"
            small
            @click="updateFixedInvoice()"
          >{{$_strings.invoice.TITLE_EDIT}}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="itemsInvoice"
            :expanded.sync="expanded"
            item-key="limitId"
            show-expand
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            @item-expanded="({value, item}) => value && fetchSubInvoice(item)"
            :loading="isLoading"
            :loading-text="$_strings.order.LOADING_TEXT"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`item.invoiceNo`]={item}>
              <p class="ma-0">{{ item.invoiceNo ? item.invoiceNo : '-' }}</p>
            </template>
            <template v-slot:[`item.price`]={item,index}>
              <p class="ma-0" v-if="item.invoiceNo">Rp. {{ formatAmount(item.price) }}</p>
              <v-text-field
                v-else
                min="0"
                step="1"
                outlined
                dense
                prefix="Rp."
                class="caption input-price"
                id="price"
                :value="formatAmount(item.price)"
                @input.native="changePrice($event, index)"
                @change="(e) => onChangePrice(e, item)"
              >
              </v-text-field>
            </template>
            <template
              v-slot:expanded-item="{ headers, item: subItem }"
            >
              <template v-if="subItem.subLoading">
                <td :colspan="headers.length">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-progress-circular
                        class="mx-auto"
                        color="primary"
                        size="30"
                        indeterminate
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-else-if="subItem.subInvoice">
                <td class="white" :colspan="headers.length">
                  <v-container fluid>
                    <v-sheet outlined class="pa-2 rounded">
                      <v-row>
                        <v-col class="pa-1">
                          <v-data-table
                            :headers="headerSubs"
                            calculate-widths
                            hide-default-footer
                            :items="subItem.subInvoice"
                            class="grey lighten-4"
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-container>
                </td>
              </template>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.INVOICE_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { thousandSeparated, dateFormat, renamePeriode } from '../../helper/commonHelpers';

export default {
  name: 'fixed-price-invoice-list-detail',
  data() {
    return {
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.TRANSPORT_TYPE,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.NUMBER_OF_LIMIT,
          value: 'limitPerNopol',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.NUMBER_OF_RITASE,
          value: 'ritaseUsage',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.LICENSE_PLATE,
          value: 'vehicleNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.PRICE,
          value: 'price',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
      ],
      headerSubs: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocation',
          class: 'black--text amber accent-3',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocation',
          class: 'black--text amber accent-3',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.DRIVER,
          value: 'driverName',
          class: 'black--text amber accent-3',
          width: '150px',
          cellClass: 'clickable',
        },
      ],
      itemsInvoice: [],
      itemSubInvoice: [],
      search: '',
      noInvoice: '',
      invoicePeriod: [
        {
          endDate: '',
          invoiceGroup: '',
          startDate: '',
        },
      ],
      invoiceListTotalEntry: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      filters: {
        invoiceNo: '',
        statusInvoice: [],
        typeInvoice: [],
        billedToCompanyId: '',
      },
      expanded: [],
      form: [],
      isLoading: false,
      subLoading: false,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.fetchInvoice();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    async fetchInvoice() {
      this.expanded = [];
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
      };
      Object.keys(this.filters).forEach((filter) => {
        if (typeof this.filters[filter] === 'object' && this.filters[filter] !== null && this.filters[filter].length) {
          filters[filter] = `qin(${this.filters[filter].toString()})`;
          return;
        }
        if (this.filters[filter]) {
          filters[filter] = this.filters[filter];
        }
      });
      if (sortBy.length) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      try {
        const { invoiceId } = this.$route.params;
        this.$root.$loading.show();
        const result = await this.$_services.invoice.listDetailFixedPriceInvoice(filters, invoiceId);
        this.itemsInvoice = result.data.limitList.contents;
        this.form = this.itemsInvoice.map((res) => ({
          invoiceId: res.invoiceId,
          invoiceFixedPriceDetailId: res.invoiceFixedPriceDetailId,
          priceUnit: res.price,
        }));
        this.noInvoice = result.data.invoiceNo;
        this.invoicePeriod.invoiceGroup = this.renamePeriode(result.data.invoicePeriod.invoiceGroup);
        this.invoicePeriod.startDate = this.dateFormat(result.data.invoicePeriod.startDate);
        this.invoicePeriod.endDate = this.dateFormat(result.data.invoicePeriod.endDate);
        this.invoiceListTotalEntry = result.data.totalData;
      } finally {
        this.$root.$loading.hide();
      }
    },
    renamePeriode,
    fetchSubInvoice(invoice) {
      if (invoice.subInvoice) return;
      this.subLoading = true;
      this.$_services.invoice.listFixedPriceLimitInvoice(invoice.invoiceId, invoice.limitId)
        .then((res) => {
          const contents = res.data.contents.map((i) => ({ ...i }));
          invoice.subLoading = false;
          this.$set(invoice, 'subInvoice', contents);
        }).finally(() => {
          this.subLoading = false;
        });
    },
    changePrice(event, index) {
      const isNumberOnly = /^\d+$/.test(event.data);
      const tempValue = this.itemsInvoice[index].price;
      if (isNumberOnly) {
        const price = `${this.itemsInvoice[index].price}${event.data}`;
        this.itemsInvoice[index].price = Number(price);
      } else if (event.data) {
        event.target.value = Intl.NumberFormat('en-US').format(tempValue);
        this.itemsInvoice[index].price = tempValue;
      } else {
        this.itemsInvoice[index].price = event.target.value.replace(/./g, '');
      }
    },
    refactorForm(event) {
      const price = event.replace(/[^,\d]/g, '');
      return price;
    },
    onChangePrice(event, item) {
      const index = this.form.findIndex((e) => e.invoiceFixedPriceDetailId === item.invoiceFixedPriceDetailId);
      this.form[index].priceUnit = this.refactorForm(event);
    },
    updateFixedInvoice() {
      this.$root.$loading.show();
      this.$_services.invoice.updateFixedPriceInvoice(this.form)
        .then((res) => {
          this.$dialog.notify.success(this.$_strings.invoice.EDIT_MESSAGE_TEXT);
          this.$router.go(-1);
        })
        .catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-price{
  top: 15px;
  width: 150px;
  .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
    border: none;
  }
}
</style>
